<template>
  <div>
    <Navlid :list="navlist"></Navlid>
    <div class="public-box">
      <!-- <div class="public-inline"> -->
      <el-form :inline="true">
        <el-form-item>
          <div>
            <span>商户姓名:</span>
            <span style="marginleft: 5px">{{
              statistics && statistics.contact_name
            }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="offset">
            <span>商户号:</span>
            <span style="marginleft: 5px">{{
              statistics && statistics.merchants_number
            }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="offset">
            <span>欠费金额:</span>
            <span style="marginleft: 5px">{{
              statistics && statistics.key_pay_price
            }}</span>
          </div>
        </el-form-item>
        <!-- <el-form-item>
          <div class="offset">
            <span>余额:</span>
            <span style="marginleft: 5px">{{
              statistics && statistics.balance
            }}</span>
          </div>
        </el-form-item> -->
        <el-form-item>
          <div class="offset">
            <span>联系电话:</span>
            <span style="marginleft: 5px">{{
              statistics && statistics.merchants_contact
            }}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="50651" @click="getRecord"
            >缴费记录
            <template slot="preImage">
              <img src="../../../assets/img/b3).png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="50652" @click="getsetRecord"
            >设置缴费记录
            <template slot="preImage">
              <img src="../../../assets/img/b3).png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" :accessId="99622" @click="getHis"
            >历史账单
            <template slot="preImage">
              <img src="../../../assets/img/b4.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton @click="exported" :accessId="12173" class="offset">
            导出
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>

      <!-- <MyButton class="offset" @click="refundvisible = true" >退款</MyButton> -->

      <!-- </div> -->
      <div v-loading="loading" style="overflow: auto">
        <div
          class
          style="margin-bottom: 30px"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <el-table
            :data="item.list"
            :header-cell-style="{
              'text-align': 'center',
              background: 'rgb(245, 245, 245)',
            }"
            :cell-style="{ 'text-align': 'center' }"
            :span-method="rowSpanMethod"
            :row-class-name="toClass"
          >
            <el-table-column
              label="时间"
              style="color: black"
              prop="property_time"
              width="100px"
            ></el-table-column>
            <el-table-column
              label="商铺号"
              prop="merchants_stall_number"
              width="150px"
            ></el-table-column>
            <el-table-column label="类别" prop="bill_name"></el-table-column>
            <el-table-column
              label="起始数值"
              prop="old_key_number"
            ></el-table-column>
            <el-table-column
              label="截止数值"
              prop="key_number"
            ></el-table-column>
            <el-table-column
              label="更新时间"
              width="220"
              prop="update_at"
            ></el-table-column>
            <el-table-column
              label="用量"
              prop="now_key_number"
            ></el-table-column>
            <el-table-column label="单价" prop="key_price"></el-table-column>
            <el-table-column label="消费费用" prop="key_pay_price_old">
              <template slot-scope="scope">{{
                $common.computationalAccuracy(
                  (scope.row.key_pay_price_old * 100) / 100
                )
              }}</template>
            </el-table-column>
            <el-table-column
              label="固定费用"
              prop="fixed_price"
            ></el-table-column>
            <el-table-column
              label="总费用"
              prop="key_total_price"
            ></el-table-column>
            <el-table-column
              label="剩余待缴金额"
              prop="key_pay_price"
            ></el-table-column>
            <el-table-column label="费用状态">
              <template slot-scope="{ row }">
                <span v-if="row.key_total_price == row.key_pay_price">{{
                  row.bill_status | billStatus
                }}</span>
                <span v-else>已缴费</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="{ row }">
                <pop tips="编辑" :accessId="80748" @myclick="openEdit(row)">
                  <img class="icon" src="../../../unit/img/edit.png" />
                </pop>
                <!-- v-if="row.bill_status == 0" -->
                <pop
                  :accessId="99623"
                  tips="设置缴费"
                  popLeft
                  @myclick="managementClick(row)"
                >
                  <img class="icon" src="@/assets/img/icon/setJF.png" />
                </pop>
              </template>
            </el-table-column>
          </el-table>
          <div class="sum-box" v-if="item.total">
            <div class="sum">合计</div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">/</div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">/</div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">/</div>
            </div>
            <div class="item">
              <div
                style="width: 220px"
                v-for="(i, ins) in item.total"
                :key="ins"
              >
                /
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                {{ item.total[ins].now_key_number }}
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">/</div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                {{
                  $common.computationalAccuracy(
                    (item.total[ins].key_pay_price_old * 100) / 100
                  )
                }}
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                {{ item.total[ins].fixed_price }}
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                {{
                  $common.computationalAccuracy(item.total[ins].key_total_price)
                }}
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                {{
                  $common.computationalAccuracy(item.total[ins].key_pay_price)
                }}
              </div>
            </div>
            <div class="item">
              <div v-for="(i, ins) in item.total" :key="ins">
                <!-- {{ item.total[ins].bill_status }} -->
                /
              </div>
            </div>
            <div class="item">
              <!-- <div v-for="(i, ins) in item.total" :key="ins">
                  <pop
                  :accessId="99623"
                    tips="设置缴费"
                    @myclick="managementClick(ins, item.list[0].property_time)"
                  >
                    <img class="icon" src="@/assets/img/icon/setJF.png" />
                  </pop>
                  <pop
                    tips="费用减免"
                    :accessId="99624"
                    @myclick="handlecharge(i, 20)"
                    v-if="i.bill_status != 10 && i.bill_key == 40"
                    popLeft
                  >
                    <img class="icon" src="@/assets/img/icon/setJM.png" alt />
                  </pop>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- 空状态 -->
      <template v-if="dataList.length == 0">
        <el-table
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            label="时间"
            style="color: black"
            prop="property_time"
            width="100px"
          ></el-table-column>
          <el-table-column
            label="商铺号"
            prop="merchants_stall_number"
            width="150px"
          ></el-table-column>
          <el-table-column label="类别" prop="bill_name"></el-table-column>
          <el-table-column
            label="起始数值"
            prop="old_key_number"
          ></el-table-column>
          <el-table-column label="截止数值" prop="key_number"></el-table-column>
          <el-table-column label="更新时间" prop="update_at"></el-table-column>
          <el-table-column label="用量" prop="now_key_number"></el-table-column>
          <el-table-column label="单价" prop="key_price"></el-table-column>
          <el-table-column
            label="消费费用"
            prop="key_pay_price"
          ></el-table-column>
          <el-table-column
            label="固定费用"
            prop="fixed_price"
          ></el-table-column>
          <el-table-column
            label="总费用"
            prop="key_total_price"
          ></el-table-column>
          <el-table-column label="费用状态"></el-table-column>
          <el-table-column label="操作"></el-table-column>
        </el-table>
      </template>
      <!-- <div class="apiPage">
        <el-pagination
          next-text="下一页"
          prev-text="上一页"
          layout="total, prev, pager, next"
          :total="total"
          :page-size="searchForm.pageSize"
          @current-change="currentChange"
        ></el-pagination>
      </div> -->
      <!-- 缴费记录 -->
      <el-dialog
        title="缴费记录"
        :visible.sync="recordvisible"
        width="80%"
        :close-on-click-modal="false"
      >
        <chargerecord ref="record"></chargerecord>
        <template slot="footer">
          <MyButton
            type="primary"
            @click="$refs.record && $refs.record.exported()"
            >导出</MyButton
          >
          <MyButton style="margin-left: 15px" @click="recordvisible = false"
            >关闭</MyButton
          >
        </template>
      </el-dialog>
      <!-- 设置缴费记录 -->
      <el-dialog
        title="设置缴费记录"
        :visible.sync="setrecordvisible"
        width="80%"
        :close-on-click-modal="false"
      >
        <setrecord ref="setrecord"></setrecord>
        <template slot="footer">
          <MyButton
            type="primary"
            @click="$refs.setrecord && $refs.setrecord.exported()"
            >导出</MyButton
          >
          <MyButton style="margin-left: 15px" @click="setrecordvisible = false"
            >关闭</MyButton
          >
        </template>
      </el-dialog>
      <!-- 历史账单 -->
      <el-dialog
        title="历史账单"
        :visible.sync="hisvisible"
        width="80%"
        :close-on-click-modal="false"
      >
        <hisbill ref="his"></hisbill>
        <template slot="footer">
          <MyButton type="primary" @click="$refs.his && $refs.his.exported()"
            >导出</MyButton
          >
          <MyButton @click="hisvisible = false" left>关闭</MyButton>
        </template>
      </el-dialog>
      <!-- 退款 -->
      <el-dialog
        title="保证金/其他费用退款"
        :visible.sync="refundvisible"
        :close-on-click-modal="false"
      >
        <el-form label-position="right" label-width="100px">
          <el-form-item label="退款账号:">
            <el-input
              v-model="editForm.acound"
              class="labelwidth"
              placeholder
            ></el-input>
          </el-form-item>
          <el-form-item label="退款户名:">
            <el-input
              v-model="editForm.acound"
              class="labelwidth"
              placeholder
            ></el-input>
          </el-form-item>
          <el-form-item label="退款金额:">
            <el-input
              v-model="editForm.acound"
              class="labelwidth"
              placeholder
            ></el-input>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <MyButton @click="refundvisible = false">取消</MyButton>
          <MyButton type="primary" left>确认</MyButton>
        </template>
      </el-dialog>
      <!-- 编辑 -->
      <el-dialog :visible.sync="editvisible" :close-on-click-modal="false">
        <el-form label-position="right" label-width="100px">
          <el-form-item label="起始数值:">
            <el-input
              v-model="editForm.old_key_number"
              @blur="minValue('old_key_number', editForm.old_key_number)"
              class="labelwidth"
              placeholder
              type="number"
              :disabled="editForm.isusage"
            ></el-input>
          </el-form-item>
          <el-form-item label="截止数值:">
            <el-input
              v-model="editForm.key_number"
              @blur="minValue('key_number', editForm.key_number)"
              class="labelwidth"
              placeholder
              type="number"
              :disabled="editForm.isusage"
            ></el-input>
          </el-form-item>
          <el-form-item label="固定费用:">
            <el-input
              v-model="editForm.fixed_price"
              @blur="minValue('fixed_price', editForm.fixed_price)"
              class="labelwidth"
              placeholder
              type="number"
              :disabled="editForm.isfixed"
            ></el-input>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <MyButton @click="editvisible = false">取消</MyButton>
          <MyButton type="primary" @click="edit" left>确认</MyButton>
        </template>
      </el-dialog>

      <el-dialog
        title="缴费管理"
        :visible.sync="managementVisible"
        width="30%"
        @close="close"
        :close-on-click-modal="false"
      >
        <el-form
          :model="management"
          :rules="managementRules"
          label-position="right"
          label-width="100px"
        >
          <el-form-item label="设置缴费:" prop="is_pay">
            <el-radio-group v-model="management.is_pay">
              <el-radio :label="1">已缴费</el-radio>
              <el-radio :label="0">未缴费</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <template slot="footer">
          <MyButton @click="managementVisible = false">取消</MyButton>
          <MyButton type="primary" @click="confirm" left>确认</MyButton>
        </template>
      </el-dialog>
    </div>
    <!-- 设置缴费 -->
    <el-dialog
      title="设置缴费"
      :visible.sync="setchagevisible"
      @close="closeSetchage"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="120px"
        ref="setchageform"
        :model="setchage"
        :rules="setchageRules"
        label-position="right"
      >
        <el-form-item label="缴费金额" prop="reduce_price">
          <el-input
            placeholder="请输入"
            type="number"
            v-model="setchage.reduce_price"
          ></el-input>
        </el-form-item>
        <el-form-item label="原因">
          <el-input
            type="textarea"
            placeholder="请输入原因"
            v-model="setchage.coupon_reason"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传凭证">
          <el-upload
            action
            :on-change="handlePreview"
            :show-file-list="false"
            :auto-upload="false"
          >
            <div class="imgBox" v-if="setchage.coupon_voucher.length > 0">
              <div
                class="imgList"
                v-for="(item, index) in setchage.coupon_voucher"
                :key="index"
              >
                <i
                  class="icon el-icon-circle-close"
                  @click.stop="del(index)"
                ></i>
                <img :src="imgUrl + item" alt="" />
              </div>
            </div>
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <MyButton @click="setchagevisible = false">取消</MyButton>
        <MyButton
          left
          type="primary"
          :disabled="!setchage.reduce_price"
          @click="confirm"
          >确认</MyButton
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
import chargerecord from "./daybillchild/chargerecord.vue";
import hisbill from "./daybillchild/hisbill.vue";
import setrecord from "./daybillchild/setRecored.vue";
export default {
  components: {
    chargerecord,
    hisbill,
    setrecord,
  },
  data() {
    return {
      navlist: [
        {
          title: "商户账单",
          url: "/charge/merchantbill/basebill",
        },
        {
          title: "日常账单",
          url: "/charge/merchantbill/daybill",
        },
        {
          title: "日常账单详情",
          url: "",
        },
      ],
      loading: false,
      managementVisible: false,
      setrecordvisible: false,
      management: {
        is_pay: "",
        bill_date: "",
        bill_key: "",
        merchants_id: "",
      },
      managementRules: {
        is_pay: [{ required: true, message: "请设置缴费", trigger: "change" }],
      },
      dataList: [],
      recordvisible: false,
      hisvisible: false,
      editvisible: false,
      editForm: {
        old_key_number: "",
        key_number: "",
        fixed_price: "",
      },
      hisList: [],
      refundvisible: false,
      searchForm: {
        merchants_id: "",
        merchants_stall_numbers: [],
      },
      total: 0,
      statistics: null,
      payStatus: "",
      typeList: [], //类型列表
      imgUrl: process.env.VUE_APP_IMG_URL,
      setchagevisible: false,
      setchage: {
        reduce_price: "",
        coupon_reason: "",
        coupon_voucher: [],
        bill_key: 20,
        coupon_type: 10,
      },
      setchageRules: {
        reduce_price: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blur",
          },
        ],
      },
      feesset: [],
    };
  },
  created() {
    this.searchForm.merchants_id = this.$route.query.merchants_id;
    this.getDetail();
    this.getDailyDetailsOverview();
    this.getFeesType();
  },
  filters: {
    billKey(e) {
      switch (e) {
        case 10:
          return "水费";
        case 20:
          return "电费";
        case 30:
          return "气费";
        case 39:
          return "合同其他费用";
        case 40:
          return "基础费用";
      }
    },
    billStatus(e) {
      switch (e) {
        case -2:
          return "未录入";
        case -1:
          return "待确认";
        case 0:
          return "未缴费";
        case 10:
          return "缴费";
      }
    },
    typeName(e) {
      switch (e) {
        case 0:
          return "水";
        case 1:
          return "电";
        case 2:
          return "气";
        case 3:
          return "其他费用";
      }
    },
  },
  methods: {
    getStalls() {},
    close() {
      this.management = {
        is_pay: "",
        bill_date: "",
        bill_key: "",
        merchants_id: "",
      };
    },
    currentChange(page) {
      this.searchForm.pageNum = page;
      this.getDetail();
    },
    managementClick(row) {
      // this.management.bill_date = time;
      // this.management.bill_key = type;
      if (row.bill_status == -1 || row.bill_status == -2) {
        let str = row.bill_status == -1 ? "确认" : "录入";
        return this.$message({
          message: `费用状态未${str}，请${str}之后在进行设置缴费`,
          type: "error",
        });
      }

      this.setchage.merchants_id = this.$route.query.merchants_id;
      this.setchage.bill_id = row.bill_id;
      // this.managementVisible = true;
      this.setchagevisible = true;
    },
    confirm() {
      // this.$request.HttpPost('/bill/dailyDetailsPay', this.management).then(res => {
      this.$request.HttpPost("/bill_coupon/add", this.setchage).then((res) => {
        this.$common.notifySuccess(res.msg);
        // this.managementVisible = false;
        this.setchagevisible = false;
        this.getDetail();
      });
    },
    getStatus(e) {
      switch (e) {
        case -2:
          return "未录入";
        case -1:
          return "待确认";
        case 0:
          return "未缴费";
        case 10:
          return "缴费";
      }
    },
    getDailyDetailsOverview() {
      console.log(this.$route.query.merchants_stall_id);
      this.$request
        .HttpPost("/bill/dailyDetailsOverview", {
          merchants_id: this.searchForm.merchants_id,
          merchants_stall_numbers: this.$route.query.stalls
            ? this.$route.query.stalls.split(",")
            : [],
          merchants_stall_id: this.$route.query.merchants_stall_id
            ? this.$route.query.merchants_stall_id
            : "",
        })
        .then((res) => {
          this.statistics = res.data;
        });
    },
    arraySpanMethod({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.timeRowSpan > 0) {
          return {
            rowspan: row.timeRowSpan,
            colspan: 1,
          };
        }
        return [0, 0];
      }
      if (columnIndex == 1) {
        if (row.merchantsRowSpan > 0) {
          return {
            rowspan: row.merchantsRowSpan,
            colspan: 1,
          };
        }
        return [0, 0];
      }
    },
    getFeesType() {
      this.$request.HttpGet("/setting/dailySetting").then((res) => {
        if (res.data) {
          this.feesset = res.data.values_struct;
        }
      });
    },
    getDetail() {
      this.loading = true;
      let arr = this.$route.query.stalls
        ? this.$route.query.stalls.split(",")
        : [];
      this.searchForm.merchants_stall_numbers = arr;
      this.$request
        .HttpPost("/bill/dailyDetailsList", this.searchForm)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            this.total = res.data.total;
            console.log(this.total);
            let resdata = res.data.list;
            let arr = [];
            resdata.forEach((item) => {
              let tempArr = [];
              let tempObj = {};
              let itemArr = item.stall_list || [];
              itemArr.forEach((items) => {
                let bill_list = items.bill_list || [];
                bill_list.forEach((ite, index) => {
                  let stallRow = index == 0 ? items.bill_list.length : 0;
                  tempArr.push({
                    ...ite,
                    merchants_stall_number: items.merchants_stall_number,
                    merchants_stall_id: items.merchants_stall_id,
                    property_time: item.group_by_create_at,
                    stallRow,
                  });

                  this.calcTotalValue(ite, tempObj);
                });
              });
              arr.push({ list: tempArr, total: tempObj });
            });
            if (arr[0].list) {
              arr[0].list.forEach((item) => {
                item.timeRow = arr[0].list.length;
              });
            }
            this.dataList = arr;
            console.log(this.dataList);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    calcTotalValue(item, obj) {
      try {
        if (!obj[item.bill_key]) {
          obj[item.bill_key] = {};
        }
        for (let i in item) {
          if (i != "bill_status") {
            if (!obj[item.bill_key][i]) {
              if (!isNaN(item[i])) {
                obj[item.bill_key][i] = 0;
              }
            }
            if (!isNaN(item[i])) {
              if (i != "key_price") {
                obj[item.bill_key][i] += item[i];
              } else {
                obj[item.bill_key][i] = item[i];
              }
            } else {
              obj[item.bill_key][i] = item[i];
            }
          } else {
            if (item[i] == 0) {
              obj[item.bill_key][i] = "欠费";
            } else {
              // 如果存在且欠费不更改
              if (!obj[item.bill_key][i] || obj[item.bill_key][i] != "欠费") {
                obj[item.bill_key][i] = "已缴纳";
              }
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 合并表格
    rowSpanMethod({ columnIndex, row, rowIndex }) {
      // if (columnIndex == 0) {
      //   if (rowIndex == 0) {
      //     return [row.timeRow, 1]
      //   }
      //   return [0, 0]
      // }
      // if (columnIndex == 1) {
      //   if (row.stallRow > 0) {
      //     return [row.stallRow, 1]
      //   }
      //   return [0, 0]
      // }
    },
    exported() {
      this.$exported(
        "/bill/dailyDetailsExport",
        this.searchForm,
        "日常账单详情.xlsx"
      );
    },
    getHis() {
      this.hisvisible = true;
      this.$nextTick(() => {
        this.$refs.his.getList();
      });
    },
    getRecord() {
      this.recordvisible = true;
      this.$nextTick(() => {
        this.$refs.record.getList();
      });
    },
    getsetRecord() {
      this.setrecordvisible = true;
      this.$nextTick(() => {
        this.$refs.setrecord.getList();
      });
    },
    openEdit(item) {
      console.log(item, "item");
      this.editForm.old_key_number = item.old_key_number;
      this.editForm.key_number = item.key_number;
      this.editForm.bill_id = item.bill_id;
      this.editForm.fixed_price = item.fixed_price;
      this.editForm.bill_key = item.bill_key;

      this.setdisabled(item.contract_fee_id, this.editForm);
      //  this.editForm.bill_status = item.bill_status == -2 ? -1:0;
      this.editForm.bill_status = -1;
      this.editvisible = true;
      console.log(this.editForm);
    },
    setdisabled(id, data) {
      let finddata = this.feesset.find((item) => {
        return item.children.property_setting_id == id;
      });
      console.log(finddata);
      if (finddata.children && finddata.children.values_struct) {
        data.isfixed =
          finddata.children.values_struct.fixed_charges == 1 ? false : true;
        data.isusage =
          finddata.children.values_struct.usage_charge == 1 ? false : true;
      }
    },
    edit() {
      this.$request
        .HttpPost("/bill/dailyDetailsEdit", this.editForm)
        .then(() => {
          this.$common.notifySuccess("编辑成功");
          this.editvisible = false;
          this.getDetail();
        });
    },
    minValue(key, value) {
      if (value < 0) {
        this.$common.notifyError("该值不能小于0");
        this.editForm[key] = 0;
      }
    },
    toClass({ row }) {
      if (row.is_abnormal == 1) {
        return "error";
      }
      return "";
    },
    del(index) {
      this.setchage.coupon_voucher.splice(index, 1);
    },
    handlePreview(file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.setchage.coupon_voucher.push(res.data.fileInfo.hostUrl);
        });
      }
    },
    closeSetchage() {
      this.isType = "";
      this.setchage = {
        reduce_price: "",
        coupon_reason: "",
        coupon_voucher: [],
        coupon_type: 10,
        bill_id: "",
        bill_key: 20,
      };
    },
  },
};
</script>
<style lang="scss">
.public-inline {
  margin-top: 15px;
}

.table-box {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .left {
    flex-shrink: 0;
    width: 50%;
  }

  .right {
    width: 40%;
    flex-shrink: 0;
  }
}

.labelwidth {
  width: 220px;
}

.sum-box {
  min-width: 1040px;
  border: 1px solid #f5f5f5;
  border-top: none;
  display: flex;
  justify-content: space-around;

  .sum {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-right: 1px solid rgb(245, 245, 245);
  }

  .item {
    text-align: center;
    flex: 1;

    > div {
      height: 71px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgb(245, 245, 245);
      box-sizing: border-box;
    }
  }
}

.el-table .error {
  td {
    &:nth-last-child(-n + 11) {
      color: red;
    }
  }
}
</style>
