<template>
<div >
  <div class="public-inline" style="margin-bottom:20px">
    <el-date-picker v-model="form.month_time" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择日期"></el-date-picker>
    <el-select class="offset" v-model="form.merchants_stall_id" placeholder="选择商铺" clearable>
      <el-option v-for="(item, index) in merchantstallList" :value="item.merchants_stall_id" :key="index" :label="item.merchants_stall_name"></el-option>
    </el-select>
    <el-select class="offset" v-model="form.bill_key" clearable placeholder="选择类别">
      <el-option :value="10" label="水费"></el-option>
      <el-option :value="20" label="电费"></el-option>
      <el-option :value="30" label="气费"></el-option>
      <el-option :value="39" label="其他费用"></el-option>
    </el-select>
    <MyButton class="offset" @click="getList">
      <span>查询</span>
      <template slot="preImage">
        <img src="../../../../unit/img/search.png" alt="" />
      </template>
    </MyButton>
  </div>
  <el-table  :header-cell-style="{'text-align': 'center', background: 'rgb(245, 245, 245)'}" :cell-style="{ 'text-align': 'center' }" :data="dataList">
    <el-table-column label="时间" show-overflow-tooltip prop="pay_time"></el-table-column>
    <el-table-column label="商铺号" prop="merchants_stall.merchants_stall_number"></el-table-column>
    <el-table-column label="类别" prop="bill_name"></el-table-column>
    <el-table-column label="起始数值" prop="old_key_number"></el-table-column>
    <el-table-column label="截止数值" prop="now_key_number"></el-table-column>
    <el-table-column label="用量" prop="key_number"></el-table-column>
    <el-table-column label="单价（元）" prop="key_price"></el-table-column>
    <el-table-column label="费用（元）" prop="key_pay_price">
      <template slot-scope="{row}">
        <span>{{(row.key_price * row.key_number).toFixed(2)}}</span>
      </template>
    </el-table-column>
    <el-table-column label="固定费用（元）" prop="fixed_price"></el-table-column>
    <el-table-column label="总费用（元）" prop="key_total_price"></el-table-column>
    <el-table-column label="缴费状态">
      <template slot-scope="{ row }">
        <span>{{ row.bill_status | billStatus }}</span>
      </template>
    </el-table-column>
  </el-table>
  <div class="apiPage">
    <el-pagination :total="total" :current-page="form.page" :page-size="10" layout="total,prev,pager,next" next-text="下一页" prev-text="上一页" @current-change="getList"></el-pagination>
  </div>
</div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        merchants_id: "",
        contract_id: "",
        page: 1,
        merchants_stall_id: "",
        month_time: "",
        bill_key: ""
      },
      total: 0,
      test: "",
      dataList: [],
      merchantstallList: [],
    };
  },
  created() {
    this.form.merchants_id = this.$route.query.merchants_id;
    this.form.contract_id = this.$route.query.contract_id;
    this.getMerchantStallList();
  },
  filters: {
    billStatus(e) {
      switch (e) {
        case -2:
          return "未录入";
        case -1:
          return "待确认";
        case 0:
          return "默认";
        case 10:
          return "缴费";
      }
    },
  },
  methods: {
    getMerchantStallList() {
      this.$request.HttpGet("/contract/stallList", {
          pageNum: 1,
          pageSize: 100000,
          merchants_id: this.form.merchants_id,
          contract_id: this.form.contract_id,
        }).then((res) => {
          if (res.data) {
            this.merchantstallList = res.data.list;
          }
        });
    },
    getList(e = 1) {
      this.form.page = e
      this.$request.HttpGet("/bill/dailyHistoryList", this.form).then((res) => {
        if (res.data) {
          this.dataList = res.data.list;
          this.total = res.data.total
        }
      });
    },
    exported() {
      this.$exported('/bill/dailyHistoryExport', this.form, '历史账单记录.xlsx')
    }
  },
};
</script>
<style lang='scss'>
</style>
